import AuthorOne from '../../assets/image/crypto/dennis.jpg';
import AuthorTwo from '../../assets/image/crypto/mustafa.jpeg';
import AuthorThree from '../../assets/image/crypto/johndisilva.jpeg';
import AuthorFour from '../../assets/image/crypto/jamesmathew.jpeg';
import FEATURE1 from '../../assets/image/crypto/tf1.svg';
import FEATURE2 from '../../assets/image/crypto/tf2.svg';
import FEATURE3 from '../../assets/image/crypto/tf3.svg';
import FEATURE4 from '../../assets/image/crypto/tf4.svg';
import PROOF1 from '../../assets/image/crypto/proof1.svg';
import PROOF2 from '../../assets/image/crypto/proof2.svg';
import PROOF3 from '../../assets/image/crypto/proof3.svg';
import PROOF4 from '../../assets/image/crypto/proof4.svg';
import PROOF5 from '../../assets/image/crypto/proof5.svg';
import PROOF6 from '../../assets/image/crypto/proof6.svg';
import JACKPOTIMG from '../../assets/image/crypto/jackpot.svg';
import BETA1 from '../../assets/image/crypto/beta-1.svg';
import BETA2 from '../../assets/image/crypto/beta-2.svg';
import BETA3 from '../../assets/image/crypto/beta-3.svg';

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Services',
    path: '#trusted',
    offset: '0',
  },
  {
    label: 'Technology',
    path: '#scalable',
    offset: '-10',
  },

  
  {
    label: 'About Us',
    path: '#footerSection',
    offset: '380',
  },
  {
    label: 'Contact Us',
    path: '#footerSection',
    offset: '380',
  },
];

export const TESTIMONIALS = [
  {
    review:
      'ZenAppStudio was able to support us end to end from articulating functional specifications and technical design through application delivery. We value the strong and positive relationship we have built with the ZenAppStudio team..',
    name: 'Dennis Hilton',
    designation: 'Head of IT, Columbia University.',
    avatar: `${AuthorOne}`,
  },
  {
    review:
      'Our project was executed with a high degree of professionalism and technical expertise. We would be happy to work with ZAP again and we hope the opportunity presents itself.',
    name: 'Mustafa Yusuf',
    designation: 'CTO of Embibe',
    avatar: `${AuthorTwo}`,
  },
  {
    review:
      'ZAP developed our Renis app which involved extensive graphics, animation and iPhone features. It was great working with ZAP team & seeing our idea take shape & turn into an app.',
    name: 'John Disilva',
    designation: 'IT Manager of Thunderbolt',
    avatar: `${AuthorThree}`,
  },
  {
    review:
      'Impressed with master class support of the team and really look forward for the future.',
    name: 'James Mathew',
    designation: 'CTO of Justme',
    avatar: `${AuthorFour}`,
  },
];
export const TRANSACTIONS_FEATURE = [
  {
    image: FEATURE1,
    title: 'Ideate',
    des: 'We brainstorm with project owners and lock the features for the project.',
  },
  {
    image: FEATURE2,
    title: 'Design',
    des: 'We use design thinking process as the heart of our project which allows us to iterate over different prototypes.',
  },
  {
    image: FEATURE3,
    title: 'Build',
    des: 'Our talented lego block developers will craft your app and do the magic for you.',
  },
  {
    image: FEATURE4,
    title: 'Ship',
    des:
      'Real Artists Ship. We deliver value to our customers on time, thus your dream app is ready for launch.',
  },
];
export const PROOFS_FEATURE = [
  {
    image: PROOF1,
    title: 'Discover',
    des: 'We elicit requirements, perform strategic analysis and articulate your product idea into a functional prototype.',
  },
  {
    image: PROOF2,
    title: 'Design',
    des: 'We design interfaces that renders a visually stimulating experience to the users.',
  },

  {
    image: PROOF4,
    title: 'Engineer',
    des: 'We leverage cutting-edge technology to build next-gen iOS, Android, Flutter, React Native, Blockchain apps and deliver immersive and memorable experiences, cost-effectively.',
  },
  {
    image: PROOF5,
    title: 'Scale',
    des: 'We scale business to new heights. We offer post-release support to ensure you relentlessly improve your product’s quality and retain your end users.',
  },
 
];
export const SCALABLE_FEATURE = [
  {
    image: JACKPOTIMG,
    title: 'Daily Jackpot',
    des: '35000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Weekly Jackpot',
    des: '250000 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Monthly Jackpot',
    des: '4999697 CLV',
  },
  {
    image: JACKPOTIMG,
    title: 'Yearly Jackpot',
    des: '300245785000 CLV',
  },
];

export const BETA_FEATURE = [
  {
    image: BETA1,
    title: 'SEPA Transfers',
    des: 'Deposit & Withdraw money.',
  },
  {
    image: BETA2,
    title: '24/7 Support',
    des: 'Always here for you.',
  },
  {
    image: BETA3,
    title: 'Secure',
    des: 'Your money is safe.',
  },
];

export const menuWidget = [
  {
    id: 1,
    title: 'About Us',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Support Center',
      },
      {
        id: 2,
        url: '#',
        text: 'Customer Support',
      },
      {
        id: 3,
        url: '#',
        text: 'About Us',
      },
      {
        id: 4,
        url: '#',
        text: 'Copyright',
      },
      {
        id: 5,
        url: '#',
        text: 'Popular Campaign',
      },
    ],
  },
  {
    id: 2,
    title: 'Our Information',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Return Policy',
      },
      {
        id: 2,
        url: '#',
        text: 'Privacy Policy',
      },
      {
        id: 3,
        url: '#',
        text: 'Terms & Conditions',
      },
      {
        id: 4,
        url: '#',
        text: 'Site Map',
      },
      {
        id: 5,
        url: '#',
        text: 'Store Hours',
      },
    ],
  },
  {
    id: 3,
    title: 'My Account',
    menuItems: [
      {
        id: 1,
        url: '#',
        text: 'Press inquiries',
      },
      {
        id: 2,
        url: '#',
        text: 'Social media directories',
      },
      {
        id: 3,
        url: '#',
        text: 'Images & B-roll',
      },
      {
        id: 4,
        url: '#',
        text: 'Permissions',
      },
      {
        id: 5,
        url: '#',
        text: 'Speaker requests',
      },
    ],
  },
];
export const Language_NAMES = [
  {
    label: 'English',
    value: 'eng',
  },
  {
    label: 'Chinese',
    value: 'chinese',
  },
  {
    label: 'Indian',
    value: 'indian',
  },
];
